.mdxeditor-popup-container {
  z-index: 9999 !important;
}

.mdxeditor-root-contenteditable {
  min-height: 200px;
  border: 1px solid rgb(214, 214, 214);
  margin-top: 20px;
  border-radius: 8px;
}

.editor-modal ol {
  list-style: decimal;
  padding-left: 10px;
}

.editor-modal ul {
  padding-left: 10px;
  list-style: circle;
}

.editor-modal h1,
h2,
h3,
h4,
h5,
h6 {
  all: revert;
  margin: 0;
  padding: 0;
}
