.doc::-webkit-scrollbar-thumb {
  border-radius: 4px;
  /* background-color: #999; */
  background-color: #1677ff;
}

.doc::-webkit-scrollbar-thumb:hover {
  /* background-color: #777; */
  background-color: #1677ff;
}
