@import 'antd/dist/reset.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #999;
  /* background-color: #1677ff; */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777;
  /* background-color: #1677ff; */
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('fonts/SFProText-Regular.woff2') format('woff2'),
    url('fonts/SFProText-Regular.otf') format('opentype'),
    url('fonts/SFProText-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('fonts/SFProText-Semibold.woff2') format('woff2');
  font-weight: 600;
}

body * {
  font-family: 'SF Pro Text';
}

h1,
h2 {
  font-size: 20px;
}

h1,
h2,
h3 {
  font-weight: 600;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.ant-btn-primary {
  background-color: #1677ff !important;
}

.ant-btn-primary:disabled {
  background-color: lightgray !important;
}

.ant-select-selection-item {
  height: inherit;
}

.ant-menu-item,
.ant-menu-item-selected {
  height: 100%;
  display: flex !important;
  align-items: center;
}

.ant-menu-item-selected {
  background-color: #1677ff !important;
}

.ant-cascader,
.select {
  width: 100% !important;
}

.ant-tabs-tab {
  @apply !px-4 !py-2 !ml-[0] !mr-[2px];
}

.ant-tabs-tab-active {
  @apply !px-4 !py-2 !bg-white;
  border-radius: 6px 6px 0px 0px;
}

.ant-tabs-nav {
  @apply !m-0;
}

.ant-tabs-content-holder {
  @apply !bg-white;
}

.ant-table-tbody > tr:hover > td {
  background-color: #bae0ff !important; /* Цвет фона при наведении */
}

.ant-input-group-addon {
  background: #fafafa !important;
}

.ant-radio-group {
  width: 100%;
}

.ant-radio-group label {
  min-width: 50%;
}

.ant-dropdown-menu-vertical {
  max-height: 40vh;
  overflow-y: auto;
}

